<template>
  <div class="productList">
    <div
      class="product-item"
      v-if="list && list.length"
    >
      <div
        :key="index"
        @click="linkToProductDetais(item.id)"
        class="productList-list"
        v-for="(item, index) in list"
      >
        <template v-if="item.video&&JSON.parse(item.video).cover&&JSON.parse(item.video).cover!=1">
          <img
            :src="JSON.parse(item.video).cover"
            class="pro_img"
            v-if="JSON.parse(item.video).cover" 
          />
          <img
            class="pro_img"
            src="@/assets/img/empty.png"
            v-else
          />
        </template>
        <template v-else>
          <img
            :src="JSON.parse(item.banner)[0]"
            class="pro_img"
            v-if="JSON.parse(item.banner)[0]"
            v-load="'oPro'"
          />
          <img
            class="pro_img"
            src="@/assets/img/empty.png"
            v-else
          />
        </template>
        <div
          class="play_video"
          v-if="item.video&&JSON.parse(item.video).url"
        >
          <i class="iconfont icon-shipinmianxing"></i>
        </div>
        <div
          class="rebang"
          v-if="item.isPopularize"
        >
          <div style="display: flex; align-items: center; justify-content: center">
            <i
              class="iconfont icon-fire"
              style="font-size: 14px; margin-right: 2px"
            ></i>
            热榜
          </div>
        </div>
        <div
          class="is_threeD"
          v-if="item.view3DUrl"
        >
          <i
            class="iconfont icon-d"
            style="
              font-size: 12px;
              margin-left: 6px;
              margin-right: 4px;
              color: #ff5c00;
            "
          ></i>
          <span style="font-size: 12px; color: #ff5c00; display: inline-block">3D</span>
        </div>
        <div
          class="qj_div"
          v-if="item.panoramaUrl"
        >
          <i class="iconfont icon-yuanxitubiao-"></i>
        </div>
        <div
          class="is_category"
          v-if="item.category"
        >
          <span>{{item.category}}</span>
        </div>
        <div class="product-content">
          <div class="product-text">{{ item.name }}</div>
          <div class="product-company">
            <span
              @click.stop="linkToExhibitorDetails(item.exhibitorId)"
              class="comp_name"
            >{{ item.exhibitor.company.shortName || item.exhibitor.company.name }}</span>
            <span
              class="diqu"
              style="font-size: 12px; color: #666"
              v-if="item.exhibitor.company.regionTxt"
            >
              <i class="iconfont icon-dizhi"></i>
              {{item.exhibitor.company.regionTxt}}
            </span>
          </div>
          <div class="user">
            <p
              class="eval"
              v-if="item.newestEvaluates&&item.newestEvaluates.length"
            >最新评价：{{item.newestEvaluates[0].content}}</p>
            <div
              class="like_count"
              v-if="item.likes&&item.likes.length"
            >
              <template v-for="(avaItem,i) in item.likes">
                <img
                  :key="i"
                  :src="avaItem.userInfo.avatarUrl"
                  v-if="avaItem.userInfo.avatarUrl"
                />
                <img
                  :key="i"
                  src="@/assets/img/user.png"
                  v-else
                />
              </template>
              <p>共{{item.likeCount}}人点赞推荐</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="!isList">
      <div class="no_more" v-if="loading">
        <p>加载中...</p>
      </div>
      <div class="no_more" v-else-if="finished">
        <p>没有更多了</p>
      </div>
      <div class="more" @click="moreClick"
        v-else-if=" list && list.length">
        <p>加载更多</p>
      </div>
      <div class="no_more" v-else-if="!list && !list.length">暂无数据！</div>
    </template>
    <template v-else>
      <div class="no_more" style="width:100%" v-if="finished && !list.length">暂无数据！</div>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "index",
  props: {
    finished: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isList:{
      type:Boolean,
      default:false,
    }
  },
  data () {
    return {
      list: [],
    };
  },
  methods: {
    ...mapActions({
      linkToProductDetais: 'linkTo/linkToProductDetais',
      linkToExhibitorDetails: 'linkTo/linkToExhibitorDetails'
    }),
    setData (arr) {
      // console.log(arr, "展品数据来了");
      this.list = arr;
    },
    moreClick () {
      this.$emit("moreClick");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.productList {
  width: 100%;
  .product-item {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
  }

  &-list:hover {
    border: 1px solid #1890ff;
    @include border_color(#1890ff);
    border-bottom: none;
    .user {
      display: block;
      position: absolute;
      top: 100%;
      width: 215px;
      z-index: 100;
      left: -1px;
      background-color: #fff;
      border: 1px solid #1890ff;
      @include border_color(#1890ff);
      border-top: none;
      .eval {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        display: -webkit-box;
        margin: 0 0 8px 14px;
      }
      .like_count {
        margin-left: 14px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        img {
          width: 21px;
          height: 21px;
          border-radius: 50%;
          border: 1px solid #fff;
          z-index: 3;
          position: relative;
          &:nth-child(2) {
            z-index: 2;
            margin-left: -10px;
          }
          &:nth-child(3) {
            z-index: 1;
            margin-left: -10px;
          }
        }
        p {
          color: #666;
          font-size: 12px;
          margin-left: 6px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      p {
        color: #666;
        font-size: 12px;
        width: 178px;
      }
    }
  }
  &-list {
    cursor: pointer;
    .user {
      display: none;
    }
    width: 215px;
    height: 316px;
    margin-left: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    border: solid 1px #ededed;
    position: relative;
    .pro_img {
      width: 213px;
      display: block;
      object-fit: cover;
      height: 214px;
      position: relative;
    }
    .play_video {
      width: 213px;
      height: 214px;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
      i {
        font-size: 48px;
        color: #fff;
      }
    }
    .rebang {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 48px;
      height: 20px;
      @include background_color(#ff5e00);
      color: #fff;
      font-size: 12px;
    }
    .is_threeD {
      position: absolute;
      top: 0;
      right: 0px;
      width: 42px;
      height: 20px;
      background-color: #fff;
      border-radius: 0px 0px 0px 5px;
      border: solid 1px #ff5c00;
      display: flex;
      align-items: center;
      z-index: 4;
    }
    .qj_div {
      position: absolute;
      right: 15px;
      top: 159px;
      background: rgba($color: #000, $alpha: 0.4);
      border-radius: 50%;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;
      i {
        color: #fff;
        font-size: 24px;
      }
    }
    .is_category {
      position: absolute;
      top: 203px;
      left: 14px;
      max-width: calc(100% - 28px);
      height: 24px;
      padding: 0 5px;
      @include background_color(#1890ff);
      border-radius: 2px;
      line-height: 24px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #fff;
      font-size: 14px;
      z-index: 4;
    }
    .product-content {
      padding-left: 14px;
      margin-top: 24px;
      .product-text {
        width: 182px;
        height: 42px;
        font-size: 14px;
        color: #333;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        display: -webkit-box;
      }
      .product-company {
        font-size: 12px;
        color: #666;
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 13px;
        .comp_name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:hover {
            @include font_color(#1890ff);
          }
        }
        .diqu {
          margin-left: 10px;
          flex-shrink: 0;
          .iconfont {
            font-size: 12px;
            color: #fe781d;
            margin-right: 6px;
          }
        }
      }
    }
    .product-renzheng {
      // width: 100px;
      height: 22px;
      background-color: #fff;
      border: solid 1px #ffe7d9;
      display: flex;
      align-items: center;
      background-color: #ffe7d9;
    }
  }
  .no_more {
    width: 100%;
    font-size: 14px;
    color: #999;
    text-align: center;
    margin: 20px 0 70px;
  }
  .more {
    width: 100%;
    font-size: 14px;
    color: #999;
    text-align: center;
    cursor: pointer;
    user-select: none;
    margin: 20px 0 70px;
    &:hover {
      @include font_color(#1890ff);
    }
  }
}
</style>
